import React from "react";
import { ContactWrapper, Section } from "./DesignationElements";

import ScrollAnimation from "react-animate-on-scroll";
function Designation() {
  return (
    <ContactWrapper>
      <div className="Container">
        <ScrollAnimation animateIn="fadeIn">
          <div className="BigCard">
            <Section>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "20px",
                  rowGap: "10px",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <span>
                  Meet <strong>Tineth Pathirage</strong>, Chief Executive
                  Officer and Founder at <strong>RORA GLOBAL</strong>,
                  Co-Founder at <strong>සේවාපොළ</strong>, a Software Engineer,
                  Software Consultant, MERN Stack Developer and Blockchain
                  Developer based in Sri Lanka, bringing over 3 years of
                  expertise in full stack development and futuristic
                  technologies.
                </span>
              </div>
            </Section>
          </div>
        </ScrollAnimation>
      </div>
    </ContactWrapper>
  );
}

export default Designation;
