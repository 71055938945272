import styled from "@emotion/styled";

export const ContactWrapper = styled.div`
  margin: 0rem 0 0rem 0;
  padding: 0rem 0 5rem 0;
  background-color: #fff;
  @media (max-width: 768px) {
    margin: -6rem 0 6rem 0;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  span {
    font-size: 1rem;
    color: #1d1d1f;
    opacity: 0.7;
    text-align: center;
    line-height: 1.5;
    font-weight: 500;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  @media (min-width: 576px) {
    span {
      font-size: 1.25rem;
    }
  }
  @media (min-width: 992px) {
    span {
      font-size: 24px;
    }
  }
`;
